.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.LoginCard {
  min-Height:100%;
  width: 500px;
  min-Width:200px;
  border-Color:rgba(0, 0, 0 , 0.70);
  background-Color: rgba(0, 0, 0 , 0.70);
}